$('.hamburger-menu').on('click', function(event) {
    event.preventDefault();
    $('.mobile-menu').show();
    $('.mobile-menu').addClass('bounceInRight');
    $('.mobile-menu').removeClass('bounceOutRight');
    $('html, body').css('overflow', 'hidden');
    $('.darken-bg').show();
});

$('.close-btn').on('click', function(event) {
    event.preventDefault();
    $('.mobile-menu').removeClass('bounceInRight');
    $('.mobile-menu').addClass('bounceOutRight');
    $('html, body').css('overflow', 'initial');
    $('.darken-bg').hide();
});