// ****************************************************************************
// *                                  RATING                                  *
// ****************************************************************************

jQuery(document).ready(function($) {


    var txt = $('.rating-caption');
    var img = $('.rating img');
    var rate = $('.rating');

    var $bad;
    var $poor;
    var $regular;
    var $good;
    var $amazing;
    var $click;

    getLanguage();

    $('.rating-caption').text($click);

    img.hover(function() {
        var rating = $(this).attr('title');
        var ratingtxt = getRating(rating);
        txt.removeClass('placeholder');
        txt.text(ratingtxt);
    });

    img.click(function() {
        img.removeClass('selected');
        $(this).addClass('selected');
    });

    rate.mouseleave(function() {
        var rating = $('.selected').attr('title');
        var ratingtxt = getRating(rating);
        if (rating) {
            txt.text(ratingtxt);
        } else {
            txt.addClass('placeholder');
            txt.text($click);
        }
    });

    function getLanguage() {
        $lang = $('html').attr('lang');

        switch ($lang) {
            case 'nl':
                $bad = 'vreselijk';
                $poor = 'matig';
                $regular = 'gemiddeld';
                $good = 'heel goed';
                $amazing = 'uitstekend';
                $click = 'klik om te beoordelen';
                break;
            case 'fr':
                $bad = 'mal';
                $poor = 'pauvre';
                $regular = 'ordinaire';
                $good = 'bien';
                $amazing = 'magnifique';
                $click = 'cliquez pour évaluer';
                break;
            case 'de':
                $bad = 'schlecht';
                $poor = 'mäßig';
                $regular = 'durchschnitt';
                $good = 'gut';
                $amazing = 'ausgezeichnet';
                $click = 'klicken um zu bewerten';
                break;
            default:
                $bad = 'bad';
                $poor = 'poor';
                $regular = 'regular';
                $good = 'good';
                $amazing = 'amazing';
                $click = 'click to rate';
        }
    }

    function getRating(rating) {
        switch (rating) {
            case 'bad':
                ratingtxt = $bad;
                break;
            case 'poor':
                ratingtxt = $poor;
                break;
            case 'regular':
                ratingtxt = $regular;
                break;
            case 'good':
                ratingtxt = $good;
                break;
            case 'amazing':
                ratingtxt = $amazing;
                break;
            default:
                ratingtxt = '';
        }

        return ratingtxt;
    }
});

$('.rating').raty({
    path: '/bundles/sitefrontend/img/rating',
    starHalf: 'star-half.svg',
    starOff: 'star-off.svg',
    starOn: 'star-on.svg',
    size: 20,
    width: false,
    half: true,
    hints: ['bad', 'poor', 'regular', 'good', 'amazing']
});

(function () {

    var targetReviews = Routing.generate('site_frontend_ajax_call_reviews');
    var fetchReviews = Routing.generate('site_frontend_ajax_fetch_reviews');

    // fetch reviews
    $.ajax({
        url: fetchReviews,
        type: "GET",
        dataType: 'json',
        success: function (data) {
            if (data.status == 'succes') {
              createReviews(data);
            } else {

            }
        }
    });

    function createReviews(data){
      var box = $('#itemContainer');
      $('.loading').remove();
      data.reviews.map(function(review){
        var html = '<li class="isLoaded">';
            html += '<div class="reviewed-item">';
            html += '<div class="reviewed-item-header">';
            html += '<h2>' + review.Naam + '</h2>';
            html += '<div class="rated" data-score="' + review.Score + '"></div>';
            html += '</div>';
            html += '<div class="streepje"></div>';
            html += '<div class="review-text"><p>' + review.Opmerkingen + '</p></div>';
            html += '</div>';
            html += '</li>';
        box.append(html);
      });
      box.append('<a href="" class="btn js-showmore">meer tonen</a>');
      // Rating
      $('.rated').raty({
          half: true,
          readOnly: true,
          size: 20,
          path: '/bundles/sitefrontend/img/rating',
          starHalf: 'star-half-alt.svg',
          starOff: 'star-off-alt.svg',
          starOn: 'star-on-alt.svg',
          score: function() {
              return $(this).attr('data-score') / 2;
          }
      });
      var average = 0;
      var total = 0;
      var score = 0;
      var $average = $('.average');
      var $total = $('.total');
      var $averageRating = $('.average-rating');

      $('.rated').each(function() {
          score += parseInt($(this).attr('data-score'));
          total++;
      });

      average = (Math.round(score / total)) / 2;
      $average.attr('data-score', average);
      $average.raty({
          path: '/bundles/sitefrontend/img/rating',
          starHalf: 'star-half-alt.svg',
          starOff: 'star-off-alt.svg',
          starOn: 'star-on-alt.svg',
          half: true,
          readOnly: true,
          width: false,
          size: 20,
          score: function() {
              return $(this).attr('data-score');
          }
      });

      $averageRating.text( ' ( '  + average.toString() + '/ 5 ) '  );
      $total.text(   total + ' reviews');
      $('.isLoaded:lt(10)').removeClass('isLoaded');
      $('.js-showmore').on('click', function(e){
        e.preventDefault();
        $('.isLoaded:lt(10)').removeClass('isLoaded');
        if($('.isLoaded').size() == 0){
          $('.js-showmore').remove();
        }
      });
    }

    function isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
        return pattern.test(emailAddress);
    };

    $('#review__submit').on('click', function () {
        if ($('[name="naam"]').val() === '' || $('[name="email"]').val() === '' || $('#reviewArea').val() === '') {
            $('#errorContainer').addClass('isVisible');
        } else {
            if ($('[name="score"]').val() === '') {
                $('#errorContainer').addClass('isVisible');
            } else if (!isValidEmailAddress($('[name="email"]').val())) {
                $('#errorContainer').addClass('isVisible');
            } else {
                $.ajax({
                    url: targetReviews,
                    data: {
                        name: $('[name="naam"]').val(),
                        email: $('[name="email"]').val(),
                        rating: $('[name="score"]').val() * 2,
                        message: $('#reviewArea').val()
                    },
                    type: "POST",
                    dataType: 'json',
                    success: function (data) {
                        if (data.status == 'succes') {
                            $('#form').fadeOut(500, function () {
                                $('#form').remove();
                            });
                            $('#errorContainer').addClass('isVisible');
                            console.log('success');
                        } else {
                            $('#errorContainer').addClass('isVisible');
                            console.log('error');
                        }
                    }
                });
            }
        }
        return false;
    });

})();
