//make current pagelink visisble
/*$(document).ready(function() {
    $(".navigation li [href]").each(function() {
        if (this.href == window.location.href) {
            $('.navigation li [href]').removeClass('active');
            $(this).addClass("active");
        }
    });
});*/

/*var headerH = $('.header').outerHeight() - 16;
$(".hero").css('margin-top', headerH);
*/
//animating the fastbooker with animate.css

$('.fastbooker').on('click', function(event) {
    event.preventDefault();
    $('.fastbooker-block').show();
    $('.fastbooker-block').toggleClass('slideInDown');
    $('.fastbooker-block').toggleClass('slideOutUp');
});

$('#close-booker').on('click', function(event) {
    event.preventDefault();
    $('.fastbooker-block').toggleClass('slideOutUp');
    $('.fastbooker-block').toggleClass('slideInDown');
   // $('.fastbooker-block').hide();
});

/*$(document).mouseup(function (e)
{
    var container = $(".fastbooker--form");

    if (!container.is(e.target) // if the target of the click isn't the container...
        && container.has(e.target).length === 0) // ... nor a descendant of the container
    {
    
    container.parent('.fastbooker-block').hide();
    }
});*/