(function(d) {
    var mL = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'];

    d.prototype.getLongMonth = d.getLongMonth = function getLongMonth(inMonth) {
        return gM.call(this, inMonth, mL);
    };

    d.prototype.getShortMonth = d.getShortMonth = function getShortMonth(inMonth) {
        return gM.call(this, inMonth, mS);
    };

    function gM(inMonth, arr) {
        var m;

        if (this instanceof d) {
            m = this.getMonth();
        } else if (typeof inMonth !== 'undefined') {
            m = parseInt(inMonth, 10) - 1; // Subtract 1 to start January at zero
        }
        return arr[m];
    }

})(Date);

(function($) {
    //Header Fastbooker
    var d = new Date(new Date().getTime());
    var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

    var arrival = $('#checkin');
    var departure = $('#checkout');
    var disco = $('#discount');


    arrival.datepicker({
        container: '#input-group',
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        weekStart: 1
    }).on('changeDate', function(e) {
        //arrival.datepicker('hide');
        departure.focus();

        var selectedDate = arrival.datepicker('getDate');
        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        departure.datepicker('setDate', nextDay);
        departure.datepicker('setStartDate', nextDay);
    });

    departure.datepicker({
         container: '#input-group',
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        weekStart: 1
    }).on("changeDate", function(e) {
        var selectedDeparture = departure.datepicker('getDate');
    });


    var btn = $('#btn-fastbooker');


    btn.click(function(e) {
        var selectedDate = arrival.datepicker('getDate');
        var arrivalValue = arrival.val();
        arrivalValue = arrivalValue.split('/');
        arrivalValue = arrivalValue[2] + '-' + arrivalValue[1] + '-' + arrivalValue[0];

        var selectedDeparture = departure.datepicker('getDate');
        var departureValue = departure.val();
        departureValue = departureValue.split('/');
        departureValue = departureValue[2] + '-' + departureValue[1] + '-' + departureValue[0];

        var discountValue = disco.val();

        e.preventDefault();
        var href = "https://reservations.cubilis.eu/acacia-hotel-brugge/Rooms/Select?Arrival=" + arrivalValue + "&Departure=" + departureValue + "&Room=&Rate=&Package=&DiscountCode=" + discountValue + "&Language=" + getCubilisLang( $('html').attr('lang') ) ;
        window.open(decoratelinker(href), '_blank');
    });

    function decoratelinker(destinationUrl) {
		var trackers, linker;
		//decorate the destinationUrl
		//if universal analytics is used
		if(ga !== undefined && typeof ga.getAll === 'function') {
			trackers = ga.getAll();
			if(trackers.length) {
			linker = new window.gaplugins.Linker(trackers[0]);
			destinationUrl = linker.decorate(destinationUrl);
			} 
		}
		//now open the popup
		//window.open(destinationUrl, '_blank'); 
		return(destinationUrl);
    }

    function getCubilisLang(lang) {
        var cubilisLang = 'en-GB';
        if (lang === 'nl') {
            cubilisLang = 'nl-NL';
        } else if (lang === 'fr') {
            cubilisLang = 'fr-FR';
        } else if (lang === 'de') {
            cubilisLang = 'de-DE';
        } else if (lang === 'es') {
            cubilisLang = 'es-ES';
        }
        return cubilisLang;
    }
})(jQuery);
