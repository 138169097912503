$('.images').each(function(){
  $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
          enabled: true,
          navigateByImgClick: true
      },
      image: {
          titleSrc: 'title'
      }
  });
});
