var formContact = $('#main-contact-form');
if (formContact.length) {
    formContact.submit(function(e){
        e.preventDefault();
        var targetUrl = Routing.generate('site_frontend_ajax_call');

        $.ajax({
            method: 'POST',
            url: targetUrl,
            data: {
                form: formContact.serializeArray()
            }
        }).done(function(data){
            if(data.status === "ok"){
                $('.js-success').addClass('showMessage');
                formContact.hide();
                formContact.trigger('reset');
            }
            if(data.status === "error"){
                $('.js-error').addClass('showMessage');
            }
        });
    });
}
