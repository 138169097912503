require('./plugins/slick.js');
require("./plugins/jquery.raty.min.js");
require("./plugins/jPages.js");
require('./plugins/magnific-popup.js');
require('./plugins/jquery.lazyload.min.js');
require("./plugins/bootstrap-datepicker.js");

require('./modules/fastbooker.js');
require('./modules/review.js');
require('./modules/photos.js');
require('./modules/slider.js');
require('./modules/mobilemenu.js');
require('./modules/headerEvents.js');
require('./modules/maps.js');
require('./modules/contact.js');

//Text area size adapting to amount of text okee dan
var txt = $(document).find('textarea');
txt.on('input', upHeight);
function upHeight() { $(this).css('height', '40px');
$(this).css('height', txt[0].scrollHeight + 3 + 'px'); }

//scroll down on click
$('#downBtn').on('click', function(event) {
	event.preventDefault();
	$('html,body').animate({scrollTop: $('#home').offset().top - 80}, 'slow');
});


//show submenu on mobile
$('.mobile-menu').find('.dropdown-toggle').on('click', function(event) {
	event.preventDefault();
  $('.menu_level_1').removeClass('clicked');
  $(this).siblings('.menu_level_1').removeClass('clicked');

  $(this).siblings('.menu_level_1').toggleClass('mobile-sub');



});

$('.navigation').find('.dropdown-toggle').on('click', function(event) {
  $('.menu_level_1').removeClass('clicked');

  //event.preventDefault();
  //$(this).toggleClass('clicked');
  $(this).siblings('.menu_level_1').toggleClass('clicked');
});

var popup = $('.js-pop');
var closepop = $('.js-closepop');

closepop.on('click', function(event) {
	event.preventDefault();
	popup.hide();
	$('body').css('overflow', 'auto');

});


popup.addClass('popup-load');
if (popup.length) {
	$('body').css('overflow', 'hidden');
}
