
var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyBgeYdyr71_cBvSIiWk_rkak-9RkS72oZ8';

(function() {
    if ($('#maps').length) {

        gmaps.load(function(google) {
            var el = document.getElementById('maps');
        var lat = 51.208122,
            long = 3.222149;
            var center = new google.maps.LatLng(lat, long);
            var icon = {
                   url: '/bundles/sitefrontend/img/svg/flag.svg',
                scaledSize: new google.maps.Size(36, 45)
            };

            var options = {
                zoom: 16,
                center: center
            };

            var map = new google.maps.Map(el, options);

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: icon
            });
        });
    }
})();
